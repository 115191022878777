<template>


    <!-- <el-alert type="info" title="门店管理岗位等级划分" :closable="false">管理门店人员等级划分</el-alert> -->
    <div style="margin-top:20px;">
        <div class="row">
            <div class="col" v-for="(item, index) in model_list" :key="item">
                <el-card class="box">
                    <b style="margin-bottom:20px;">{{ index + 1 }}</b>
                    <el-form>
                        <el-form-item label="岗位">
                            <el-select v-model="item.post_id" placeholder="">
                                <el-option v-for="x in positionList" :key="x" :value="x.id" :label="x.name"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="权限">
                            <el-checkbox v-model="item.is_overall" label="管理所有门店"></el-checkbox>
                            <div class="help-block">勾选则此岗位人员可管理所有门店、否则可单独设置门店管理员</div>
                        </el-form-item>
                        <el-form-item label="点位">
                            <el-input v-model="item.decimal_rate" placeholder="">
                                <template #suffix>%</template>
                            </el-input>
                            <div class="help-block">提成点位百分比，例如：1.12%则输入1.12</div>
                        </el-form-item>
                    </el-form>

                </el-card>
                <div class="space">
                    <div class="arrow"></div>
                    <el-button type="primary" style="margin-top:10px;" icon="plus" link @click="onInsert(index)" />
                    <div v-if="model_list.length > 1" style="margin-top:10px;">
                        <el-button type="danger" link icon="minus"  @click="onRemove(index)" />
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-row flex-center" style="margin-top:40px;">
            <el-button type="primary" @click="onEdit" v-if="!isEdit" icon="Edit">编辑</el-button>
            <el-button type="success" @click="onSubmit" :loading="saving" v-else>保存</el-button>
        </div>
    </div>

</template>

<script>
export default {
    data() {
        return {
            isEdit: false,
            saving: false,
            model_list: [],
            //岗位列表
            positionList: []
        }
    },
    created() {
        this.loadPost();
        this.loadData();
    },
    methods: {

        /**
         * 获取所有的岗位
         */
        loadPost() {
            this.$http.get('seller/v1/common/post').then(res => {
                if (res.code == 0) {
                    this.positionList = res.data;
                }
            })
        },

        /**
         * 加载数据
         */
        loadData() {
            this.$http.get('seller/v1/shop/position').then(res => {
                if (res.code == 0) {
                    this.model_list = res.data;
                }
            })
        },

        /**
         * 编辑
         */
        onEdit() {
            this.isEdit = true;
            if (this.model_list.length > 0) {
                console.log('');
            } else {
                this.model_list.push({
                    post_id: '',
                });
            }
        },
        /**
         * 插入
         * @param {*} i 
         */
        onInsert(i) {
            this.model_list.splice(i + 1, 0, {});
        },
        /**
         * 移除
         */
        onRemove(i) {
            this.model_list.splice(i, 1);
        },
        /**
         * 保存
         */
        onSubmit() {
            if (this.model_list.length <= 0) {
                this.$message({
                    message: '请添加数据',
                    type: 'info'
                });
                return;
            }
            this.saving = true;
            this.$http.post('seller/v1/shop/position/edit', this.model_list).then(res => {
                this.saving = false;
                if (res.code == 0) {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.isEdit = false;
                    this.loadData();
                }
            })
            console.log(this.model_list);
        }
    },
}
</script>

<style scoped>
.row {
    display: flex;
    align-items: center;
}

.row .col {
    flex-grow: 1;
    position: relative;
    display: flex;
}

.row .col:last-child {
    margin-right: 0px;
}

.row .col .space {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
}


.row .col .arrow {
    position: relative;
    width: 15px;
    height: 15px;
    border: 2px solid var(--el-text-color-placeholder);
    border-left: 0;
    border-top: 0;
    transform: rotate(-45deg) translateY(-4px);
    margin-bottom: 10px;
}

.row .col .box {
    flex: 1;
}
</style>